import React, { useEffect, useRef, useState } from "react";
import Review from "./ReviewPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { notification } from "antd";
import apiRequest from "./utils/apiRequest";

const Wrapper = () => {
  const location = useLocation();
  const [validToken, setValidToken] = useState(false);
  const [reviewData, setReviewData] = useState();
  const params = new URLSearchParams(location.search);
  const getReviewData = async (reviewId) => {
    const url = `reviews/${reviewId}`;
    const result = await apiRequest(url, "GET");
    if (result?.data) {
      setReviewData(result.data);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Something went wrong. Please try again later.",
      });
    }
  };
  const getSubQData = async (reviewId, subQId) => {
    const url = `reviews/${reviewId}?question_id=${subQId}`;
    const result = await apiRequest(url, "GET");
    if (result?.data) {
      setReviewData(result.data);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Something went wrong. Please try again later.",
      });
    }
  };
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const reviewId = pathSegments[pathSegments?.length - 1];
    if (params.get("token")) {
      setValidToken(true);
    }
    if (reviewId && !params.get("subQId")) {
      getReviewData(reviewId);
    }
    if (params.get("subQId")) {
      getSubQData(reviewId, params.get("subQId"));
    }
  }, [location]);

  return (
    <Routes>
      {validToken ? (
        <Route
          path={"/:mode?/review/:id"}
          element={
            <Review
              reviewData={reviewData}
              getReviewData={getReviewData}
              getSubQData={getSubQData}
            />
          }
        />
      ) : (
        <Route path="*" element={<h2>Unauthorized access</h2>} />
      )}
    </Routes>
  );
};

export default Wrapper;
