import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import styles from "./style.module.scss";

const DropdownSelect = ({
  options,
  reset,
  disableMenu,
  bgColor,
  selectedOptions,
  setSelectedOptions,
  hideImg,
  hiddenTriggerLabel,
  triggerEvent,
  placement,
}) => {
  const handleSelection = (id) => {
    const selected = options.find((option) => option.id === id);
    setSelectedOptions({
      id: selected?.id,
      label: selected?.label,
      img: selected?.img,
      value: selected?.value,
    });
  };

  const menu = (
    <Menu className={styles.menu} onClick={(e) => handleSelection(e.key)}>
      {!disableMenu &&
        options?.map((option) => (
          <Menu.Item className={styles.menuBox} key={option.id}>
            {option.img && <img src={option.img} width={18} height={18} />}{" "}
            {option.label}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className={styles.DropdownContainer}>
      {reset ? (
        <Dropdown
          className={styles.Dropdown}
          style={{ backgroundColor: bgColor }}
          overlay={menu}
          trigger={triggerEvent ? [triggerEvent] : ["click", "hover"]}
          placement={placement ? placement : "bottomLeft"}
        >
          <img
            src={selectedOptions ? selectedOptions.img : options[0].img}
            width={18}
            height={18}
          />
        </Dropdown>
      ) : (
        <Dropdown
          className={styles.Dropdown}
          style={{ backgroundColor: bgColor }}
          overlay={menu}
          trigger={triggerEvent ? [triggerEvent] : ["click", "hover"]}
          placement={placement ? placement : "bottomLeft"}
        >
          {!hideImg ? (
            <img
              src={selectedOptions ? selectedOptions.img : options[0].img}
              width={18}
              height={18}
            />
          ) : (
            <span className={styles.hiddenTriggerLabel}>
              {hiddenTriggerLabel}
            </span>
          )}
        </Dropdown>
      )}
    </div>
  );
};

export default DropdownSelect;
