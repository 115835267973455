import React, { useEffect, useState } from "react";
import ReviewItem from "./ReviewItem";
import { Spin, Switch, notification } from "antd";
import { useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import apiRequest from "../utils/apiRequest";
const Review = ({ reviewData, getReviewData, getSubQData }) => {
  const [topics, setTopics] = useState([]);
  const [topicSentiments, setTopicSentiments] = useState({});
  const [topicIndices, setTopicIndices] = useState({});
  const [topicIntents, setTopicIntents] = useState({});
  const [feedbackModeEnabled, setFeedbackModeEnabled] = useState(false);
  const [intentIndices, setIntentIndices] = useState({});
  const [indicesSentiment, setIndicesSentiment] = useState({});
  const [topicsTree, setTopicsTree] = useState([]);
  const [highlightedText, setHighlightedText] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const location = useLocation();
  let text = "";
  let sentiment = "";
  let date = "";
  let platformId = "";
  let reviewPlatformId = null;
  let rate = null;
  let username = "";
  let intents = "";
  let pic = "";
  let title = "";
  let ratingText = "";
  let IsRatingNPS = false;
  const params = new URLSearchParams(location.search);
  const createLevelWiseTopics = (topics) => {
    if (topics && topics.length > 0) {
      let levelWiseTopics = [];

      function processChildren(children) {
        let result = [];
        children.forEach((child) => {
          let processedChild = {
            id: child.topicId,
            label: child.topicName,
            children: [],
          };
          if (child.children && child.children.length > 0) {
            processedChild.children = processChildren(child.children);
          }
          result.push(processedChild);
        });
        return result;
      }

      topics.forEach((topic) => {
        let level1Children = [];
        if (topic.children && topic.children.length > 0) {
          level1Children = processChildren(topic.children);
        }
        let topicStructure = {
          id: topic.topicId,
          label: topic.topicName,
          children: level1Children,
        };
        levelWiseTopics.push(topicStructure);
      });

      return levelWiseTopics;
    }
  };

  useEffect(() => {
    if (reviewData && Object.keys(reviewData).length > 0) {
      const data = reviewData.Analysis;
      const tpcs = data.reduce((acc, curr) => {
        curr.topics.forEach((topic) => {
          if (!acc.find((t) => t.id === topic.id)) {
            acc.push({
              id: topic.id,
              label: topic.label,
              type: "custom",
              sentiment: topic.sentiment,
              intent: topic.intent,
            });
          }
        });
        return acc;
      }, []);

      setTopics(tpcs);
      const tpcSentiments = data.reduce((acc, curr) => {
        curr.topics.forEach((topic) => {
          if (!acc[topic.id]) {
            acc[topic.id] = topic.sentiment;
          }
        });
        return acc;
      }, {});
      setTopicSentiments(tpcSentiments);
      const tpcIntents = data.reduce((acc, curr) => {
        curr.topics.forEach((topic) => {
          if (!acc[topic.id]) {
            acc[topic.id] = topic.intent;
          }
        });
        return acc;
      }, {});
      setTopicIntents(tpcIntents);
      const tpcIndices = data.reduce((acc, curr) => {
        curr.topics.forEach((topic) => {
          if (!acc[topic.id]) {
            acc[topic.id] = [];
          }
          acc[topic.id].push([
            parseInt(curr.startIndex, 10),
            parseInt(curr.endIndex, 10),
          ]);
        });
        return acc;
      }, {});
      setTopicIndices(tpcIndices);
      const indicesSentiment = {};
      const intentIndices = {};
      data.forEach((item) => {
        const { startIndex, endIndex, topics } = item;
        const indices = [parseInt(startIndex), parseInt(endIndex)];
        topics.forEach((topic) => {
          const { intent, sentiment } = topic;
          if (intentIndices[intent]) {
            intentIndices[intent].push(indices);
          } else {
            intentIndices[intent] = [indices];
          }
          indicesSentiment[`${indices[0]}, ${indices[1]}`] = sentiment;
        });
      });

      setIndicesSentiment(indicesSentiment);
      setIntentIndices(intentIndices);
    }
  }, [reviewData]);

  text = params.get("subQId") ? reviewData?.Answer : reviewData?.text;
  date = reviewData?.date;
  IsRatingNPS = reviewData?.IsRatingNPS;
  ratingText = reviewData?.RatingText;
  title = reviewData?.QuestionDesc;
  sentiment = reviewData?.sentiment;
  intents = reviewData?.intent;
  platformId = 28;

  const getTopicsTree = async () => {
    const url = `me/topics`;
    const result = await apiRequest(url, "POST");
    if (result?.topics) {
      setTopicsTree(result.topics);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Something went wrong. Please try again later.",
      });
    }
  };
  useEffect(() => {
    setHighlightedText([]);
    if (feedbackModeEnabled) {
      getTopicsTree();
    }
  }, [feedbackModeEnabled]);
  return (
    <Spin
      spinning={
        !reviewData || Object.keys(reviewData)?.length === 0 || reviewsLoading
      }
    >
      <div className={styles.mainWindow}>
        {reviewData && Object.keys(reviewData)?.length > 0 && (
          <>
            <div className={styles.header}>
              <div className={styles.feedbackMode}>
                <span className={styles.feedback}>
                  {feedbackModeEnabled ? "Feedback Mode" : "View only Mode"}{" "}
                  <Switch
                    checked={feedbackModeEnabled}
                    onClick={() => {
                      setFeedbackModeEnabled(!feedbackModeEnabled);
                    }}
                    size="small"
                  />
                </span>
              </div>
            </div>
            <ReviewItem
              text={text}
              title={title}
              sentiment={sentiment}
              IsRatingNPS={IsRatingNPS}
              sequences={reviewData.Analysis?.map((seq) => {
                return {
                  ...seq,
                  seq_id: `${reviewData.review_id} ⌀ ${seq.startIndex}_${seq.endIndex}`,
                };
              })}
              classifierSequences={reviewData.ClassifierAnalysis?.map((seq) => {
                return {
                  ...seq,
                  seq_id: `${reviewData.review_id} ⌀ ${seq.startIndex}_${seq.endIndex}`,
                };
              })}
              date={date}
              platformId={platformId}
              relatedTopics={topics}
              reviewPlatformId={reviewPlatformId}
              rate={rate}
              username={username}
              intents={
                typeof intents === "string" ? intents.split(".")[0] : intents
              }
              pic={pic}
              showAiTopicLabel={false}
              showCustomTopicLabel={true}
              showCustomTopicParentLabel={true}
              showPivotsInReviews={false}
              topicSentiments={topicSentiments}
              topicIndices={topicIndices}
              topicIntents={topicIntents}
              intentIndices={intentIndices}
              indicesSentiment={indicesSentiment}
              ratingText={ratingText}
              feedbackModeEnabled={feedbackModeEnabled}
              reviewId={reviewData.review_id}
              levelWiseTopics={createLevelWiseTopics(topicsTree)}
              highlightedText={highlightedText}
              setHighlightedText={setHighlightedText}
              getReviewData={getReviewData}
              getSubQData={getSubQData}
              reviewsLoading={reviewsLoading}
              setReviewsLoading={setReviewsLoading}
            />
          </>
        )}
      </div>
    </Spin>
  );
};

export default Review;
