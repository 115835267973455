const apiRequest = async (url, method, payload) => {
  const params = new URLSearchParams(window.location.search);
  const pathSegments = window.location.pathname.split("/");
  let baseUrl = "";
  if (pathSegments?.includes("dev")) {
    baseUrl = "https://embed.pivony.com/api/demo/iframe/";
  } else if (pathSegments?.includes("local")) {
    baseUrl = "http://localhost:5000/api/demo/iframe/";
  } else {
    baseUrl = "https://embed.pivony.com/api/v1/";
  }
  const finalUrl = baseUrl + url;
  const result = await new Promise((resolve) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", "Bearer " + params.get("token"));
    var requestOptions = {
      method: method,
      headers: myHeaders,
    };
    if (payload) {
      requestOptions.body = JSON.stringify(payload);
    }
    fetch(finalUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error(error);
        resolve({ cancel: true });
      });
  });
  return result;
};

export default apiRequest;
