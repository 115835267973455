import React from "react";
import { BrowserRouter } from "react-router-dom";
import Wrapper from "./Wrapper";

function App() {
  const theme = "light";
  document.documentElement.style.setProperty(
    "--body",
    theme === "dark" ? "#100d31" : "#ffffff"
  );
  document.documentElement.style.setProperty(
    "--font",
    theme === "dark" ? "#ffffff" : theme === "light" ? "#000000" : "#4A4D4E"
  );

  document.documentElement.style.setProperty(
    "--border",
    theme === "dark" ? "#262250" : theme === "light" ? "#E8E0F4" : "#f4f4f4"
  );

  document.documentElement.style.setProperty(
    "--active",
    theme === "brand" ? "#4A4D4E" : "#4400aa"
  );
  document.documentElement.style.setProperty(
    "--secondary",
    theme === "dark" ? "#1c193b" : theme === "light" ? "#E8E0F4" : "#f4f4f4"
  );

  document.documentElement.style.setProperty(
    "--line",
    theme === "dark" ? "#262250" : "#00000024"
  );
  document.documentElement.style.setProperty(
    "--popup",
    theme === "dark" ? "#262250" : "#ffffff"
  );

  return (
    <BrowserRouter>
      <Wrapper />
    </BrowserRouter>
  );
}

export default App;
